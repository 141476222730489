const portuguese = {
  text: 'portuguese',
  general: {
    error: {
      error1: 'Desculpe!',
      error2: 'Ocorreu um erro, tente novamente.',
      error3: 'VOLTAR AO INÍCIO',
      error4:
        'Não há tarifas para os dados selecionados, por favor tente novamente.',
      error5: 'Código inválido',
      error6: 'Usuário inválido',
      error7: 'e-mail inválido',
      error8: 'O e-mail é obrigatório',
      error9: 'É necessária uma senha',
      error10: 'A sua reserva foi cancelada com sucesso.',
      error11: 'Desculpe, ocorreu um erro',
      error12: 'Desculpe, ocorreu um erro no servidor',
      error13: 'Dados do cartão inválidos',
      error14: 'A reserva foi cancelada',
      error15: 'Nenhuma reserva encontrada',
      error16:
        'A sua reserva foi cancelada com sucesso. Deverá entrar em contato conosco pelo telefone <b>55-11-4506-2956</b> ou e-mail: <b>reservas@avisbudgetgsa.com.br</b> para processar o reembolso da reserva.',
      error17:
        'Você deve selecionar o tipo de cliente e preencher o formulário',
      error18: 'Desculpe! Ocorreu um erro, tente novamente.',
      error19:
        'Não há veículos disponíveis, a loja está fechada no horário solicitado.',
      error20:
        'A loja está fechada no horário solicitado. Você pode escolher da loja mais próxima para fazer sua reserva.',
      error21: 'Código não válido para o veículo selecionado.',
      error22:
        'Ocorreu um erro no processamento de seu pagamento. Por favor, aguarde um momento e tente novamente.',
      error23:
        'Desculpe, ocorreu um erro. Por favor, aguarde um momento e tente novamente.',
      success1: 'Usuário validado',
      success2: 'Código validado com sucesso',
      success3: 'Parceiro validado, seu desconto foi aplicado com sucesso',
      success4: 'A sua reserva foi enviada com sucesso.',
      no_rates_found: 'Não há tarifas disponíveis para o destino selecionado.'
    },
    days: {
      monday: 'Segunda-feira',
      thursday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      tuesday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado',
      sunday: 'Domingo',
      text: 'Loja fechada',
    },
    phone: 'Telefone',
    navbar: {
      home: 'Início',
      offices: 'Lojas',
      searchReserve: 'Procurar reserva',
      signUp: 'Condições de Aluguel',
      signIn: 'Agencia de Viagens',
      contact: 'Fale Conosco',
    },
    footer: {
      subscribe: {
        text1: 'Inscreva-se para receber nossas novidades e promoçoes',
        text2: 'Nome e Sobrenome',
        text3: 'Endereço de E-mail',
        text4: 'Assinatura bem sucedida',
        button1: 'CADASTRAR',
      },
      links: {
        title1: 'Sobre nós',
        text1_1:
          'VJV é o representante exclusivo das marcas Avis e Budget no Brasil para vendas internacionais, oferecendo ' +
          'dois serviços de locação de veículos no exterior.',
        title2: 'Negócios',
        text2_1: 'Cadastro de Cliente Corporativo',
        text2_2: 'Cadastro de Agência de Viagens',
        text2_3: 'Faça login na sua conta',
        text2_4: 'Sistemas GDS',
        title3: 'Atendimento ao Cliente',
        text3_1: 'Perguntas frequentes',
        text3_2: 'Procurar uma reserva',
        text3_3: 'Entre em contato conosco',
        text3_4: 'Achados e perdidos',
        title4: 'Legal',
        text4_1: 'Termos de uso',
        text4_2: 'Aviso de cookies',
        text4_3: 'Aviso de privacidade',
        text4_4: 'Políticas de cancelamento',
      },
      copyright: {
        text1: '2022 © Avis Budget GSA Brasil - Todos os direitos reservados',
        text2: 'Powered by',
        text3: 'Developed by',
      },
    },
    progressbar: {
      text1: 'Selecione o seu veículo',
      text2: 'Detalhes da reserva e produtos opcionais',
      text3: 'Confirmação da reserva',
    },
  },
  home: {
    widget: {
      title: 'Seu próximo destino está ',
      title2: 'a um click de distância',
      text1: 'Loja de retirada',
      text2: 'Loja de devolução',
      text3: 'Idade',
      text4: 'Procurar reserva',
      button1: 'SELECIONE O VEÍCULO',
    },
    banner: {
      title1: 'O MELHOR PREÇO DO MERCADO ',
      title2: 'GARANTIDO!',
      text1_1: 'Modificações e cancelamento',
      text1_2: 'sem custo',
      text2_1: 'Aluge agora, parcelando em',
      text2_2: 'até 10x',
      text3_1: 'Promoções e tarifas',
      text3_2: 'exclusivas',
    },
  },
  step1: {
    location: {
      title1: 'RETIRADA',
      title2: 'DEVOLUÇÃO',
      button1: 'TROCAR',
      text1: 'Telefone',
      text2: 'Consulte mapa e horários de atendimento',
    },
    filter: {
      title: 'FILTRAR OS RESULTADOS POR',
      text1: 'Grupo de veículo',
      item1_1: 'Grupo',
      text2: 'Número de passageiros',
      item2_1: 'Passageiros',
      text3: 'Tipo de transmissão',
      item3_1: 'Transmissão Automática',
      item3_2: 'Transmissão Manual',
      button1: 'RESTAURAR',
    },
    card: {
      button1: 'QUERO RESERVAR AGORA!',
      title1: 'TARIFAS DISPONÍVEIS',
      title2: 'PRÉ-PAGO',
      title3: 'PAGAR NO DESTINO',
      item1: 'lugares',
      item2: 'mala grande',
      item6: 'malas grandes',
      item3: 'mala pequena',
      item7: 'malas pequenas',
      item4: 'portas',
      item5: 'Transmissão Automática',
      item8: 'Ar Condicionado',
      text: 'Inclusões',
    },
  },
  step2: {
    switch: {
      currency1: 'USD',
      currency2: 'R$',
      item1: 'Dólar Americano',
      item2: 'Real Brasileiro',
    },
    card: {
      title1: 'Veículo selecionado',
      title2: 'Características',
      alert:
        'Avis e Budget confirma grupos ou categorias e não marcas ou modelos de veículos, este são ' +
        'apenas para orientação e podem variar sem aviso prévio. A imagem é apenas para fins ilustrativos.',
      item1: 'lugares',
      item2: 'mala grande',
      item7: 'malas grandes',
      item3: 'mala pequena',
      item8: 'malas pequenas',
      item4: 'portas',
      item5: 'Transmissão Automática',
      item6: 'Ar Condicionado',
    },
    location: {
      title1: 'Você retira o veículo no',
      title2: 'Você devolve o veículo no',
      text1: 'Telefone',
      button1: 'VER MAIS INFORMAÇÃO',
    },
    equipment: {
      title: 'Coberturas / Equipamento opcional',
      text1:
        'O equipamento opcional não pode ser reservado, é apenas requerido. É confirmado e pago na loja' +
        'de retirada do veículo. O seu custo não está incluido no preço pré-pago deste aluguel e, um preço estimado' +
        'é mostrado como um guia.',
      text2: 'Quantidade',
    },
    prices: {
      title1: 'Resumo',
      title2: 'Tarifa selecionada',
      title3: 'DETALHES DE IMPOSTOS E OUTRAS TAXAS',
      title4: 'CUSTOS PARA EQUIPAMENTOS OPCIONAIS',
      title5: 'Total de impostos e outras taxas',
      title6: 'Total equipamento especial',
      title7: 'TOTAL ESTIMADO',
      title8: 'TOTAL PRÉ-PAGO',
      text1: 'Período de locação',
      text2: 'Detalhes da Tarifa',
      text3: 'Tarifa básica',
      text4:
        '(1) Taxas e restrições podem ser aplicadas a motoristas com idade inferior á idade mínima. ',
      text5: 'Ver restrições de idade nas condições gerais de aluguel.',
      text6:
        '(2) Estas taxas NÃO estão incluídas na tarifa básica, algumas delas só podem ser pagas no destino. ' +
        'Consulte o seu agente de viagens.',
      text7:
        '(3) Equipamento especial não pode ser reservado, é apenas solicitado. É confirmado e pago na loja de retirada de veículos.' +
        'O seu custo não está incluído no preço pré-pago da locação e, um preço estimado é mostrado como um guia.',
      text8: 'Li e aceito os',
      text9: 'termos e condições',
      text10: 'em vigor no país de destino e os ',
      text11: 'termos e condições gerais do aluguel.',
      button1: 'CONFIRMAR A RESERVA',
    },
    customer1: 'Cliente Final',
    customer2: 'Cliente Corporativo',
    customer3: 'Agência de viagens',
    info1: {
      title1: 'Tipo de cliente',
      title2: 'Dados do passageiro',
      title3: 'Informação de voo',
      title4: 'Parceiro',
      text1: 'Nome completo',
      text2: 'Sobrenome',
      text3: 'E-mail',
      text4: 'Número Wizard / RapidRez',
      text5: 'Companhia aérea',
      text6: 'Passageiro Frequente',
      text7: 'Número de voo',
      text8: 'Número de membro',
      text9: 'Selecione uma opção',
      text10: 'Código de parceiro',
      button1: 'APLICAR',
    },
    info2: {
      text1: '¡Bem-vindo ',
      text2: 'de',
      text3: 'Para confirmar a reserva, favor completar os dados do passageiro',
      text4: 'Email',
      text5: 'Senha',
      text6: 'Nome do usuário do Cliente Corporativo',
      button1: 'ENVIAR',
    },
    info3: {
      text1: '¡Bem-vindo ',
      text2: 'de',
      text3:
        'Não se esqueça de incluir seu número Avis Budget Rewards para receber incentivos importantes.',
      text4: 'E-mail',
      text5: 'Senha',
      text6: 'Nome do usuário da Agência de viagens',
      text7: 'Número Avis Budget Rewards',
      button1: 'ENVIAR',
      button2: 'APLICAR',
    },
    optional: {
      title: 'Informação Opcional',
      text1: 'Insira o código AWD / BCD',
      text2: 'Tem um número de cupom?',
      button1: 'EXCLUIR',
      button2: 'APLICAR',
    },
  },
  step3: {
    header: {
      title1: 'Sua reserva foi gerada corretamente!',
      title2:
        'Mas lembre-se que para finalizá-la você deve efetuar o pré-pagamento.',
      text1: 'A reserva foi selecionada para pagamento no destino (POD).',
      text2:
        'Dirija-se à loja da locadora e mencione o número de confirmação desta reserva. Lembre-se que o pagamento ' +
        'de impostos e taxas, bem como qualquer equipamento adicional necessário, será feito na loja de locação.',
      text3:
        'O titular da reserva deve apresentar a carteira de habilitação válida, passaporte e cartão de crédito internacional em seu nome ' +
        'para retirar o veiculo. A locadora bloqueará o valor correspondente em seu cartão de crédito como garantia. Para reservas na Europa, ' +
        'além dos documentos acima mencionados, é necessárip apresentar uma carteira de habilitação internacional.',
      text4:
        'Consulte abaixo as opções de pagamento que oferecemos para esta reserva, ou ligue para nós por telefone: ' +
        '(55) 11-4506-2956 ou 0800-2155-2847. Lembre-se que o pagamento de impostos e taxas, bem como qualquer equipamento adicional necessário, ' +
        'será feito na loja de locação.',
      button1: 'ESCOLHA Á FORMA DE PAGAMENTO',
    },
    confirm: {
      title: 'Confirmação da reserva',
      text1: 'Reserva',
      text2: 'Nome e sobrenome',
      text3: 'Período de locação',
      text4: 'Total estimado',
      text5: 'Você retira o veículo no',
      text6: 'Loja de retirada',
      text7: 'Você devolve e veículo no',
      text8: 'Loja de devolução',
      text9: 'Pagar en destino',
      button1: 'IMPRIMIR RESERVA EM PDF',
      button2: 'ENVIAR PARA O E-MAIL',
      button3: 'FAZER UMA NOVA RESERVA',
      button4: 'CANCELAR RESERVA',
    },
    taxes: {
      title1: 'Tarifa, impostos e taxas',
      title2: 'Tarifa básica',
      title3: 'Impostos e outras taxas',
      title4: 'Custos para equipamentos opcionais',
      title5: 'Total',
      text1:
        '(1) Taxas e restrições podem ser aplicadas a conductores com idade inferior á idade mínima.' +
        'Ver restrições de idade nas condições gerais de aluguel.',
      text2:
        '(2) Estas taxas NÃO estão incluídas na tarifa básica, algumas delas só podem ser pagas no destino. ' +
        'Cosulte o seu agente de viagens.',
      text3:
        '(3) Equipamento especial não pode ser reservado, é apenas requerido. É confirmado e pago na loja de retirada de veículos.' +
        'O seu custo não está incluído no preço pré pago da locação, e um valor estimado é mostrado como um guia.',
    },
    card: {
      title1: 'Veículo selecionado',
      title2: 'Características',
      title3: 'Informação da tarifa',
      alert:
        'Avis e Budget confirmam grupos ou categorias e não marcas ou modelos de veículos, que são ' +
        'apenas para orientação e podem variar sem aviso prévio. A imagem é apenas para fins ilustrativos.',
      item1: 'lugares',
      item2: 'mala grande',
      item7: 'malas grandes',
      item3: 'mala pequena',
      item8: 'malas pequenas',
      item4: 'portas',
      item5: 'Transmissão Automática',
      item6: 'Ar Condicionado',
    },
    location: {
      title1: 'Você retira o veículo no',
      title2: 'Você devolve o veículo no',
      title3: 'Informação da loja',
    },
    info: {
      title1: 'Dados da Agência de Viagens',
      title2: 'Informação de voo',
      title3: 'Dados do Cliente Corporativo',
      text1: 'Nome da Agência de Viajes',
      text2: 'Endereço de E-mail',
      text3: 'Número Avis Budget Rewards',
      text4: 'Nome do Agente de Viagens',
      text5: 'Companhia aérea',
      text6: 'Número de voo',
      text7: 'Passageiro Frequente',
      text8: 'Número de membro',
      text9: 'Nome do Cliente Corporativo',
      text10: 'Nome do usuário',
      text11: 'Nome de operador',
    },
    codes: {
      text1: 'Código AWD / BCD',
      text2: 'Número de cupom',
      text3: 'Wizard / RapidRez',
    },
  },
  step4: {
    conditions1: 'Li e aceito os ',
    conditions2: 'termos e condições gerais do aluguel',
    header: {
      title1: 'Desejo pagar esta reserva agora',
      title2: 'Confirmação da reserva',
      title3: 'Taxa de Câmbio',
      text1:
        'Confira abaixo as opções de pagamento que oferecemos para esta reserva.',
      text2:
        'Lembre-se que o pagamento de impostos e as taxas, bem como qualquer equipamento adicional necessário, ' +
        'será feito na loja de locação',
      text3: 'Falta pouco!',
      text4: 'Selecione a forma de pagamento para confirmar sua reserva',
      text5: 'CANCELE ESTA RESERVA SEM PAGAR',
    },
    typepayment: {
      title1: 'Métodos de pagamento para esta reserva',
      title2: 'Selecione um método de pagamento',
      opcion1: 'Transferência bancária',
      opcion2: 'Cartão de crédito',
      opcion3: 'Pagamento faturado',
    },
    payment: {
      title1: 'Detalhe do pré-pago',
      title2: 'Total en dólares',
      title3: 'Pré-pago',
      title4: 'Detalhe da reserva',
      title5: 'Equipamento opcional solicitado',
      title6: 'Total da reserva',
      title7: 'Total',
      title8: 'Tarifa básica',
      title9: 'Impostos e outras taxas',
      title10: 'Imposto IRRF',
      title11: 'Tarifa básica + impostos',
      title12: 'Comisssão pré-pago',
      title13: 'Importante',
      title14: 'Comisssão final',
      title15: 'Tarifa Net',
      text:
        'Cada operação está sujeita a um processo de verificação antifraude. O valor total da reserva será cobrado em moeda ' +
        'local (BRL). O pagamento do Equipamento Opcional solicitado será em dólares americanos na loja de início de locação.',
    },
    opcion1: {
      title:
        'Instruções importantes para pagamento por transferência bancária:',
      text1:
        'Para manter o câmbio atual de sua reserva, o comprovante de pagamento deve ser enviado para o email',
      text4: 'reservas@avisbudgetgsa.com.br',
      text5: 'até, no máximo, amanhã às 10:00.',
      text2:
        'Após esse período, haverá atualização cambial, de acordo com a cotação do dia.',
      text3:
        'O voucher será enviado após confirmação da operação junto à instituição bancária, no prazo de até 3 dias úteis.',
      button1: 'ACEITAR A TRANSFERÊNCIA BANCÁRIA',
    },
    opcion2: {
      title: 'Para pagar com cartão de crédito',
      text1: 'Cadastro de Pessoa Física (CPF)',
      text2: 'Insira o número do cartão de crédito',
      text3: 'Titular do cartão',
      text4: 'Selecione a quantidade de parcelas',
      text5: '"Vencimento (MM/AA)"',
      text6: 'Código de segurança (CVV)',
      text7: 'Aceito os ',
      text8: 'Termos e Condições',
      text9: 'Endereço',
      text10: 'Número',
      text11: 'Estado',
      text12: 'Cidade',
      text13: 'Endereçamento Postal (CEP)',
      button1: 'PAGAR ESTA RESERVA AGORA',
    },
    opcion3: {
      title: 'Pagar com pago faturado',
      text1:
        'O pagamento faturado é uma forma de pagamento a prazo. Você faz a compra e recebe seu voucher e imediato mas o pagamento é' +
        'feito após envio de fatura. Em caso de dúvidas, consulte os detalhes do processo e prazos de pagamento com a equipe comercial.',
      text2: 'O valor de ',
      text3:
        'será cobrado no total da sua fatura, na data de vencimento correspondente.',
      button1: 'ACEITAR PAGAMENTO FATURADO',
    },
  },
  step5: {
    opcion1: {
      title: 'Para pagar por transferência bancária',
      title2: 'Você pagou a reserva com sucesso!',
      text1: 'Uma vez confirmado o pagamento, o voucher será enviado para',
      text2: 'Após o pagamento, não esqueça de enviar o comprovante para',
      text3: 'Valor a transferir',
      text6: 'reservas@avisbudgetgsa.com.br',
      button1: 'ALTERAR FORMA DE PAGAMENTO',
      item1: 'Nome da instituição financeira ou Banco',
      item2: 'Agência',
      item3: 'Número de conta corrente',
      item4: 'CNPJ (identificação estadual da empresa)',
      item5: 'Razão Social',
      res1: 'Banco Itaú',
      res2: '5590',
      res3: '10923-6',
      res4: '30.772.921/0001-33',
      res5: 'Ventura & Jesus Ventura Consult. Ass. Intercâmbio Ltda.',
    },
    opcion2: {
      title1: 'Você pagou a reserva com sucesso!',
      title2: 'Confirmação da reserva',
      text1: 'Debitamos ',
      text2: 'do seu cartão de crédito terminando em',
      text3: 'Enviaremos o comprovante para o seguinte e-mail',
      text4: 'paolabelon@gmail.com',
      text5:
        'Lembre-se que o pagamento de determinados impostos e taxas, bem como equipamento adicional necessário, ' +
        'será feito na loja de locação.',
    },
    opcion3: {
      title1: 'Esta reserva foi marcada como Pagamento Faturado',
      title2: 'Confirmação da reserva',
      title3: 'Você pagou a reserva com sucesso!',
      text1: 'Cobraremos o valor de',
      text2: 'na sua fatura, que sera liquidada na data correspondente.',
      text3: 'O comprovante para esta reserva será enviado para',
      text4: 'javier@amex.com',
      text5:
        'O pagamento faturado é uma forma de pagamento a prazo. Você faz a compra e recebe seu voucher e imediato mas o pagamento é' +
        'feito após envio de fatura. Em caso de dúvidas, consulte os detalhes do processo e prazos de pagamento com a equipe comercial.',
    },
  },
  offices: {
    title1: 'Procurar uma loja',
    placeholder: 'Procure por país, aeroporto, cidade ou endereço',
    text1: 'Codigo GDS',
  },
  modal: {
    search: {
      title1: 'Procurar reserva',
      title2: 'Sobrenome do passageiro',
      title3: 'Número de reserva',
      title4: 'PROCURAR',
    },
    inclusive: {
      title1: 'Informação da tarifa',
    },
    irrf: {
      title1: 'Imposto IRRF',
      title2: 'O que é o IRRF?',
      text1: 'Medida Provisória nº 907/2019 (republicada em 28.11.2019).',
      text2:
        'Alíquota do IRRF incidente sobre os valores pagos, creditados, entregues, empregados ou remetidos para pessoa física ou jurídica residente ou domiciliada no exterior, destinados à cobertura de gastos pessoais, no exterior, de pessoas físicas residentes no País, em viagens de turismo, de negócios, a serviço, de treinamento ou missões oficiais.',
    },
    creditCard: {
      title1: 'Taxa Cartão de Crédito',
      title2: 'O que significa?',
      text1:
        'Quando você paga por uma reserva com seu cartão de crédito, uma taxa porcentual é aplicada para a conveniência de seu uso.',
    },
    loja: {
      title1: 'Informação da loja',
    },
    pre: {
      title1: 'Informação importante',
      text1: 'Nossas tarifas',
      text2: 'pré-pagas',
      text3: 'tem descontos especiais',
      text4: 'O valor da reserva pode ser pago',
      text5: 'em parcelas',
      text6: 'e através de diferentes meios de pagamento',
      text7: 'Consiga uma',
      text8: 'taxa de cambio preferencial',
      text9: 'que será indicada uma vez confirmada sua reserva',
      text10:
        'O preço apresentado abaixo não inclui o Imposto sobre remessa vigente para a indústria do turismo. O mesmo será somado ao valor no próximo passo, assim que selecionado o carro e tarifa. Para maiores informações:',
      text11: 'clique',
      link: 'https://receita.economia.gov.br/noticias/ascom/2016/janeiro/receita-regulamenta-tributacao-de-remessas-ao-exterior',
    },
    pod: {
      title1: 'Informação importante',
      text1: 'As tarifas a pagar no destino não têm descontos especiais.',
      text2:
        'O preço da reserva só pode ser pago no destino através de cartão de crédito.',
    },
    step0: {
      title1: 'Trocar',
    },
    cancel: {
      title1: 'Você tem certeza de cancelar esta reserva?',
      title2: 'SIM',
      title3: 'NÃO',
      text1: 'Reserva',
      text2: 'Nome e sobrenome',
    },
    email: {
      title1: 'Endereço de E-mail',
      text1: 'E-mail',
      text2: 'ENVIAR',
    },
  },
  contact: {
    title1: 'DIGA UM OI!',
    title2: 'Entre em contato conosco',
    title3: 'Rua Frei Caneca, 558, sala 101, CEP 01307-001, Consolação',
    title4: 'Telefone: 55-11-4506-2956',
    title5: 'Horário de atendimento: Segunda a Sexta de 08:00-18:00',
    text1: 'Nome',
    text2: 'Email',
    text3: 'Assunto',
    text4: 'Mensagem',
    text5: 'Formulário enviado. Nós entraremos em contato com você',
    button1: 'ENVIAR MENSAGEM',
  },
  faq: {
    title1: 'FAQ',
    title2: 'Tire suas dúvidas',
    location1: 'Locações Avis:',
    location2: 'Locações Budget:',
    question1:
      'Quais são as vantagens de um voucher pré-pago em relação ao pagamento no destino?',
    question2: 'Já devolvi o carro e recebi novas cobranças. A que se refere?',
    question3: 'Como posso obter um recibo da minha locação internacional?',
    question4:
      'Fiz uma reserva pelo site mas quero fazer o pré-pagamento. É possível?',
    question5:
      'Quais são as vantagens de um voucher pré-pago em relação ao pagamento no destino?',
    question6: 'Posso parcelar o pré pagamento da minha reserva?',
    response1_1:
      'Fazendo o pré pagamento da sua reserva internacional você garante o câmbio do dia do pagamento, evitando variações cambiais bruscas, além de poder efetuar o parcelamento em até 6 vezes sem juros. Além disso, você não compromete o limite do seu cartão de crédito durante a viagem, podendo utilizá-lo para outros fins.',
    response2_1:
      'Se recebeu cobranças adicionais referente à sua locação você pode acessar o seu recibo de locação em através dos sites abaixo para verificar detalhes das cobranças. Você precisará indicar o país da locação, o sobrenome do condutor e o número da reserva ou contrato de locação.',
    response2_2: 'https://www.avis.com/en/reservation/get-e-receipt',
    response2_3: 'https://www.budget.com/en/reservation/get-e-receipt',
    response2_4:
      'Caso ainda assim precise de esclarecimentos adicionais, pode enviar um email a nossa equipe de pós venda que estará à disposição para esclarecer:',
    response2_5: 'posvenda.avis@avisbudgetgsa.com.br',
    response2_6: 'posvenda.budget@avisbudgetgsa.com.br',
    response3_1:
      'Para obter um recibo de locação você pode acessar os sites abaixo e baixar o recibo. Você precisará indicar o país da locação, o sobrenome do condutor e o número da reserva ou contrato de locação.',
    response3_2: 'https://www.avis.com/en/reservation/get-e-receipt',
    response3_3: 'https://www.budget.com/en/reservation/get-e-receipt',
    response4_1:
      'Sim. As reservas internacionais podem ser pré pagas através de transferência bancária ou cartão de crédito. Para efetuar o pré pagamento de sua reserva envie email para',
    response4_2: 'reservas@avisbudgetgsa.com.br',
    response4_3: 'ou ligue para nossa central no telefone',
    response4_4: '11-4506-2956.',
    response5_1:
      'Sim. Na retirada do veículo será feito o bloqueio caução em cartão de crédito que deverá estar em nome do titular da reserva. O valor do bloqueio caução varia de acordo com o país de locação e com o grupo de veículo contratado. Para maiores informações, entre em contato com a nossa central no telefone',
    response5_2: '11-4506-2956.',
    response6_1:
      'Sim. Todas as reservas internacionais que sejam pré pagas no Brasil podem ser parceladas em até 6 vezes sem juros no cartão de crédito. Para efetuar o pré pagamento de sua reserva entre em contato com a nossa central no telefone',
    response6_2: '11-4506-2956.',
  },
};

export default portuguese;
